.main-div{
  width: 100vw;
  height: 100vh;
  background-color: rgb(3, 11, 32);
}

.title-welcome-div{
  width: 100%;
  text-align: center;
  padding-top: 12vh;
}
.title-welcome{
  font-size: 4rem;
  font-family: 'RK';
  color: #FFFFFF;
}

.title-ouroboros-div{
  width: 100%;
  text-align: center;
  padding-top: 5vh;
}
.title-ouroboros{
  font-size: 5rem;
  font-family: 'RK';
  color: #F7C73F;
}

.title-redirecting-div{
  width: 100%;
  text-align: center;
  padding-top: 5vh;
}
.title-redirecting{
  font-size: 3rem;
  font-family: 'RK';
  color: #FFFFFF;
}

.title-nothing{
  font-size: 2rem;
  font-family: 'RK';
  color: #FFFFFF;
}

.btn-div{
  width: 100%;
  align-content: center;
}
.btn-here{
  width: 146px !important;
  height: 55px;
  text-align: center;
  margin-left: calc(50% - 73px);
  font-size: 1.75rem;
  line-height: 55px;
  color: #FFF;
  font-family: 'RK';
  cursor: pointer;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}
.btn-here:hover{
  color: #F7C73F;
}

.fade-in-text {
  animation: fadeIn 3s;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@media (max-width: 575px){
  .title-welcome-div{
    padding-top: 7vh;
  }
  .title-welcome{
    font-size: 3rem;
  }
  .title-ouroboros{
    font-size: 4rem;
  }
  .title-redirecting{
    font-size: 2rem;
  }
  .title-nothing{
    font-size: 1.5rem;
  }
}